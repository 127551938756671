import React from 'react';
import QuickSearchToggleButton from './QuickSearchToggleButton';
import { BuildingIconStyled, CompanyIconStyled } from '../QuickSearchStyled';
import { QuickSearchToggleContainer } from './QuickSearchToggleStyled';
import { QUICK_SEARCH_OPTIONS } from '../constants';
import { QuickSearchType } from '../types';

interface QuickSearchToggleProps {
  onClickBuildingSearchButton(): void;
  onClickCompanySearchButton(): void;
  selectedSearchType: QuickSearchType;
}

const QuickSearchToggle: React.FC<QuickSearchToggleProps> = ({
  onClickBuildingSearchButton,
  onClickCompanySearchButton,
  selectedSearchType,
}: QuickSearchToggleProps) => (
  <QuickSearchToggleContainer>
    <QuickSearchToggleButton
      onClick={onClickBuildingSearchButton}
      label={QUICK_SEARCH_OPTIONS.buildings.label}
      isActive={selectedSearchType.id === QUICK_SEARCH_OPTIONS.buildings.id}
      left
    >
      <BuildingIconStyled aria-hidden="true" />
    </QuickSearchToggleButton>
    <QuickSearchToggleButton
      onClick={onClickCompanySearchButton}
      label={QUICK_SEARCH_OPTIONS.companies.label}
      isActive={selectedSearchType.id === QUICK_SEARCH_OPTIONS.companies.id}
    >
      <CompanyIconStyled aria-hidden="true" />
    </QuickSearchToggleButton>
  </QuickSearchToggleContainer>
);

export default QuickSearchToggle;
