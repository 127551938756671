import { AuthenticatedRequestObservable } from 'apis/request';
import {
  UnassignEpcActionTypes,
  UnassignEpcAction,
} from 'connected/EpcCertificatesPanel/UnassignEpc/types';
import { endpoints } from 'globalConstants';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { unassignEpcSuccess, unassignEpcFail } from 'store/actions/epcActions';

type UnassignEpcEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const unassignEpcEpic: Epic = (
  action$,
  state$,
  { authRequest }: UnassignEpcEpicDependencies,
) =>
  action$.pipe(
    ofType(UnassignEpcActionTypes.UNASSIGN_EPC),
    mergeMap((action: UnassignEpcAction) => {
      const url = endpoints.epc;

      return authRequest(state$, {
        url,
        method: 'DELETE',
        body: action.payload,
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status > 200) {
            throw new Error(`error loading ${url}`);
          }
          return unassignEpcSuccess(
            action.payload.epcId,
            action.payload.buildingId,
          );
        }),
      );
    }),
    catchError((error) => of(unassignEpcFail(error))),
  );

export default unassignEpcEpic;
