import React from 'react';

import Thumbnail from 'components/Thumbnail/Thumbnail';
import { TableCellStyled } from '../TableStyled';

import { TableCellProps, TableCellType } from '../types';

import { TABLE_ACTIONS, TABLE_CELL_TYPE } from '../constants';
import { ActionCell } from './ActionCell/ActionCell';
import { IconCell } from './IconCell/IconCell';
import { CheckboxCell } from './CheckboxCell';
import { ModalTriggerCell } from './ModalTriggerCell/ModalTriggerCell';
import { ValuesCell } from './HeadingCell/ValuesCell';
import { HeadingThumbnailTooltipLinkCell } from './HeadingCell/HeadingThumbnailTooltipLinkCell';

const TableCell: React.FC<TableCellProps> = ({
  actions = [],
  icon,
  value,
  values,
  tagline,
  linkTo,
  thumbnailTooltipUrl,
  isBold,
  hasHighlight,
  savCrmId,
  savCrmNearestId,
  cellType,
  isConfirmedTotalRent,
  cellKey,
  hasMatchingRecords,
  vertical,
  introhiveRelationshipStrengthScore,
  introhiveNearestRelationshipStrengthScore,
  introhiveScoreId,
  subValue,
  onChange,
}: TableCellProps) => {
  const isIconCell =
    actions.length > 0 && actions[0].type === TABLE_ACTIONS.DISPUTED;

  const singleValue: TableCellType = {
    value,
    linkTo,
    icon,
    tagline,
    isBold,
    savCrmId,
    savCrmNearestId,
    cellKey,
    hasMatchingRecords,
    isConfirmedTotalRent,
    introhiveRelationshipStrengthScore,
    introhiveNearestRelationshipStrengthScore,
    introhiveScoreId,
  };

  const cellValues: TableCellType[] = values || [singleValue];

  const getCell = () => {
    switch (true) {
      case cellType === TABLE_CELL_TYPE.ACTION:
        return <ActionCell actions={actions} cellKey={cellKey} />;

      case cellType === TABLE_CELL_TYPE.ICON:
        return (
          <IconCell
            actions={actions}
            isIconCell={isIconCell}
            vertical={vertical}
          />
        );

      case cellType === TABLE_CELL_TYPE.CHECKBOX:
        return (
          <CheckboxCell
            value={value}
            subValue={subValue}
            isIconCell={isIconCell}
            onChange={onChange}
          />
        );

      case cellType === TABLE_CELL_TYPE.MODAL_TRIGGER && !values && !value:
        return <ModalTriggerCell actions={actions} />;

      case cellType === TABLE_CELL_TYPE.IMAGE && !!value:
        return <Thumbnail src={value} fitted />;

      case !!linkTo && !!thumbnailTooltipUrl:
        return (
          <HeadingThumbnailTooltipLinkCell
            value={singleValue}
            imageTooltipUrl={thumbnailTooltipUrl || ''}
          />
        );

      default:
        return <ValuesCell values={cellValues} />;
    }
  };

  return (
    <TableCellStyled
      isIconCell={isIconCell}
      hasHighlight={hasHighlight}
      textAlign={actions.length > 0 ? 'right' : 'left'}
    >
      <span>{getCell()}</span>
    </TableCellStyled>
  );
};

export default TableCell;
