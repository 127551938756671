import React, { useState } from 'react';
import {
  ThumbnailContainer,
  LoadingSpinnerStyled,
  ImageStyled,
} from './ThumbnailStyled';

interface ThumbnailProps {
  src: string;
  fitted?: boolean;
}

export const Thumbnail: React.FC<ThumbnailProps> = ({
  src,
  fitted = false,
}) => {
  const [loading, setLoading] = useState(true);

  const handleOnLoad = () => setLoading(false);
  const handleOnError = () => {
    setLoading(false);
  };

  return (
    <ThumbnailContainer fitted={fitted}>
      {loading && <LoadingSpinnerStyled />}
      <ImageStyled
        key={src}
        src={src}
        fitted={fitted}
        isLoading={loading}
        onLoad={handleOnLoad}
        onError={handleOnError}
      />
    </ThumbnailContainer>
  );
};

export default React.memo(Thumbnail);
