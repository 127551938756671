import { GlobalApiFailError } from 'store/actions/types';
import { ResourceIdentifiers } from 'pages/Details/types';
import { Vertical } from 'globalConstants';

export type QuickSearchType = {
  id: number;
  name: string;
  label: string;
  formLabel: string;
  formPlaceholder: string;
};

export enum QuickSearchActionTypes {
  QUICK_SEARCH_QUERY = 'QUICK_SEARCH_QUERY',
  QUICK_SEARCH_API_SUCCESS = 'QUICK_SEARCH_API_SUCCESS',
  QUICK_SEARCH_API_FAIL = 'QUICK_SEARCH_API_FAIL',
  QUICK_SEARCH_RESET = 'QUICK_SEARCH_RESET',
  QUICK_SEARCH_ITEM_CLICKED = 'QUICK_SEARCH_ITEM_CLICKED',
  QUICK_SEARCH_SET_SEARCH_TYPE = 'QUICK_SEARCH_SET_SEARCH_TYPE',
}

export type QuickSearchQueryAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_QUERY;
  payload: {
    keyword: string[];
    pageNumber: number;
    skipResetDetails?: boolean;
  };
};

export type QuickSearchQueryItemClickedAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_ITEM_CLICKED;
  payload: {
    vertical: string;
    entityId: string;
  };
};

export type QuickSearchApiSuccessAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_API_SUCCESS;
  payload: QuickSearchResponse;
};

export type QuickSearchApiFailAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_API_FAIL;
  payload: GlobalApiFailError;
};

export type QuickSearchResetAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_RESET;
  payload?: boolean;
};

export type QuickSearchSetSearchTypeAction = {
  type: QuickSearchActionTypes.QUICK_SEARCH_SET_SEARCH_TYPE;
  payload: QuickSearchType;
};

export type QuickSearchOnResultItemClick = (
  payload: ResourceIdentifiers,
) => void;

export type QuickSearchColorMode = 'light' | 'dark';

type QuickSearchResultBase = {
  id: string;
  address: string;
  otherAddresses?: QuickSearchResult[];
};

export type QuickSearchResultBuilding = QuickSearchResultBase & {
  type: Vertical.Building;
  primaryUse: string;
  alternativeAddress?: string;
  formerName?: string;
  alias?: string;
};

export type QuickSearchResultCompany = QuickSearchResultBase & {
  type: Vertical.Company;
  name: string;
};

export type QuickSearchResultMeta = {
  page: number;
  pages: number;
  pageSize: number;
};

export type QuickSearchResponse = {
  results: QuickSearchResult[];
  meta: QuickSearchResultMeta;
};

export type QuickSearchResult =
  | QuickSearchResultBuilding
  | QuickSearchResultCompany;

export type QuickSearchActions =
  | QuickSearchApiSuccessAction
  | QuickSearchApiFailAction
  | QuickSearchQueryAction
  | QuickSearchResetAction
  | QuickSearchQueryItemClickedAction
  | QuickSearchSetSearchTypeAction;

export type QuickSearchOptions = {
  id: number;
  name: string;
  label: string;
  formLabel: string;
  formPlaceholder: string;
};
