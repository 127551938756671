import { Reducer } from 'redux';
import {
  PollingEpcActions,
  PollingEpcActionTypes,
} from 'store/actions/pollingEpcActions';
import {
  AddEpcActions,
  AddEpcActionTypes,
} from 'connected/EpcCertificatesPanel/AddEpc/types';
import {
  UnassignEpcActions,
  UnassignEpcActionTypes,
} from 'connected/EpcCertificatesPanel/UnassignEpc/types';

interface PollingEpcState {
  polling: boolean;
  epcIds: string[];
  buildingId: string;
  success: 'success' | 'undetermined' | 'fail' | 'null';
  type: PollingEpcType;
}

export enum PollingEpcType {
  NONE = 'NONE',
  ADD_EPC = 'ADD_EPC',
  UNASSIGN_EPC = 'UNASSIGN_EPC',
}

export const initialState: PollingEpcState = {
  polling: false,
  epcIds: [],
  success: 'null',
  buildingId: '',
  type: PollingEpcType.NONE,
};

type Actions = PollingEpcActions | AddEpcActions | UnassignEpcActions;

const pollingEpcReducer: Reducer<PollingEpcState, Actions> = (
  state: PollingEpcState = initialState,
  action,
): PollingEpcState => {
  switch (action.type) {
    case AddEpcActionTypes.ADD_EPC_SUCCESS: {
      return {
        ...state,
        polling: true,
        epcIds: action.payload.epcIds,
        buildingId: action.payload.buildingId,
        type: PollingEpcType.ADD_EPC,
      };
    }

    case UnassignEpcActionTypes.UNASSIGN_EPC_SUCCESS: {
      return {
        ...state,
        polling: true,
        epcIds: [action.payload.epcId],
        buildingId: action.payload.buildingId,
        type: PollingEpcType.UNASSIGN_EPC,
      };
    }

    case PollingEpcActionTypes.POLLING_EPC_SUCCESS: {
      return {
        ...state,
        polling: false,
        success: 'success',
        type: PollingEpcType.NONE,
      };
    }

    case PollingEpcActionTypes.POLLING_EPC_UNKNOWN: {
      return {
        ...state,
        polling: false,
        success: 'undetermined',
        type: PollingEpcType.NONE,
      };
    }

    case PollingEpcActionTypes.POLLING_EPC_FAIL: {
      return {
        ...state,
        polling: false,
        success: 'fail',
        type: PollingEpcType.NONE,
      };
    }

    default:
      return state;
  }
};

export default pollingEpcReducer;
