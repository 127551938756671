import styled, { css } from 'styled-components/macro';

import { ReactComponent as BuildingIcon } from 'assets/icons/building.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

interface QuickSearchTitleProps {
  searchIsActive: boolean;
}

export const QuickSearchWrapper = styled.div`
  width: 100%;
  grid-column: 2;
  position: relative;
  padding: 0;
`;

export const QuickSearchTitle = styled.h2<QuickSearchTitleProps>`
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  height: ${(props) => (props.searchIsActive ? 0 : 'auto')};
  opacity: ${(props) => (props.searchIsActive ? 0 : 1)};
  transition: all 0.2s;

  &::before,
  &::after {
    content: '';
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    background-color: ${(props) => props.theme.colorPalette.branding.white};
    position: relative;
  }

  &::before {
    margin-left: -50%;
    right: 20px;
  }

  &::after {
    margin-right: -50%;
    left: 20px;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    right: -80px;
  }
`;

export const customInputStyles = css`
  border-radius: 0 3px 3px 0;
  border: 1px solid #818298;
  border-left: 0;
  padding: 1em 50px 1em 1em;
`;

export const BuildingIconStyled = styled(BuildingIcon)`
  margin-right: 10px;
`;

export const CompanyIconStyled = styled(CompanyIcon)`
  margin-right: 10px;
`;

export const SearchIconStyled = styled(SearchIcon)`
  width: 24px;
  height: 24px;
  fill: grey;
  position: absolute;
  top: 50%;
  right: ${(props) => props.theme.spacing.small};
  transform: translateY(-50%);
`;

export const SearchTypeIndicator = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.yellow};
  padding: ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.small}
    ${(props) => props.theme.spacing.default};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 0 0 3px;
  border: 1px solid ${(props) => props.theme.colorPalette.secondary.midGrey};
  border-right: 0;

  &:hover,
  &:focus {
    border: 1px solid ${(props) => props.theme.colorPalette.secondary.darkGrey};
    border-right: 0;
  }
`;

export const SearchWrapperForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MultiSearchFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing.xsmall};
`;

export const TextInputWrapper = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
`;
