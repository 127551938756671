import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAthenaUser } from 'store/actions/athenaUserActions';
import { globalModalShow } from 'store/actions/globalModalActions';
import { MODAL_ID } from 'connected/GlobalModal/constants';
import { getAthenaUserViewedReleaseNotesVersion } from 'store/selectors/athenaUserSelectors';
import ReleaseNotesModal from './ReleaseNotesModal';
import { compareVersions } from './helpers';

const ReleaseNotes: React.FC = () => {
  const dispatch = useDispatch();
  const [releaseNotesBody, setReleaseNotesBody] = useState('');

  const viewedReleaseNotesVersion = useSelector(
    getAthenaUserViewedReleaseNotesVersion,
  );
  const athenaFriendlyVersion = (window as any)
    .ATHENA_FRIENDLY_VERSION as string;

  useEffect(() => {
    dispatch(fetchAthenaUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('release_notes_never')) {
        return;
      }

      if (
        athenaFriendlyVersion !== undefined &&
        viewedReleaseNotesVersion !== undefined &&
        compareVersions(viewedReleaseNotesVersion!, athenaFriendlyVersion) ===
          -1
      ) {
        const url = `/other/release-notes/${athenaFriendlyVersion}.md`;
        const response = await fetch(url);
        const body = await response.text();

        setReleaseNotesBody(body);
        dispatch(globalModalShow(MODAL_ID.RELEASE_NOTES));
      }
    })();
  }, [viewedReleaseNotesVersion, athenaFriendlyVersion, dispatch]);

  return (
    <ReleaseNotesModal
      athenaFriendlyVersion={athenaFriendlyVersion}
      releaseNotesBody={releaseNotesBody}
    />
  );
};

export default ReleaseNotes;
