import { GlobalApiFailError } from 'store/actions/types';

import {
  QuickSearchActionTypes,
  QuickSearchQueryAction,
  QuickSearchApiSuccessAction,
  QuickSearchApiFailAction,
  QuickSearchResetAction,
  QuickSearchSetSearchTypeAction,
  QuickSearchQueryItemClickedAction,
  QuickSearchResponse,
  QuickSearchType,
} from 'connected/QuickSearch/types';

export const quickSearchQuery = (payload: {
  keyword: string[];
  skipResetDetails?: boolean;
  pageNumber: number;
}): QuickSearchQueryAction => ({
  type: QuickSearchActionTypes.QUICK_SEARCH_QUERY,
  payload,
});

export const quickSearchItemClicked = (payload: {
  vertical: string;
  entityId: string;
}): QuickSearchQueryItemClickedAction => ({
  type: QuickSearchActionTypes.QUICK_SEARCH_ITEM_CLICKED,
  payload,
});

export const quickSearchApiSuccess = (
  payload: QuickSearchResponse,
): QuickSearchApiSuccessAction => ({
  type: QuickSearchActionTypes.QUICK_SEARCH_API_SUCCESS,
  payload,
});

export const quickSearchApiFail = (
  payload: GlobalApiFailError,
): QuickSearchApiFailAction => ({
  type: QuickSearchActionTypes.QUICK_SEARCH_API_FAIL,
  payload,
});

export const quickSearchReset = (
  skipResetDetails = false,
): QuickSearchResetAction => ({
  type: QuickSearchActionTypes.QUICK_SEARCH_RESET,
  payload: skipResetDetails,
});

export const quickSearchSetSearchType = (
  payload: QuickSearchType,
): QuickSearchSetSearchTypeAction => ({
  type: QuickSearchActionTypes.QUICK_SEARCH_SET_SEARCH_TYPE,
  payload,
});
