import SearchTextInput from 'components/Forms/Inputs/SearchInput/SearchTextInput';
import React from 'react';
import { customInputStyles, MultiSearchFormStyled } from './QuickSearchStyled';
import QuickSearchToggle from './Toggle/QuickSearchToggle';
import { SingleSearchFormProps } from './SingleSearchForm';

interface MultiSearchFormProps extends SingleSearchFormProps {
  onClickBuildingSearchButton(): void;
  onClickCompanySearchButton(): void;
}

const MultiSearchForm: React.FC<MultiSearchFormProps> = ({
  value,
  onChange,
  onFocus,
  onKeyUp,
  onSubmit,
  onClickBuildingSearchButton,
  onClickCompanySearchButton,
  searchType,
}: MultiSearchFormProps) => {
  return (
    <MultiSearchFormStyled name="quick-search" onSubmit={onSubmit}>
      <QuickSearchToggle
        onClickBuildingSearchButton={onClickBuildingSearchButton}
        onClickCompanySearchButton={onClickCompanySearchButton}
        selectedSearchType={searchType}
      />
      <SearchTextInput
        name="quick-search"
        label={searchType.formLabel}
        onFocus={onFocus}
        labelIsHidden
        placeholder={searchType.formPlaceholder}
        customStyles={customInputStyles}
        onChange={onChange}
        onKeyUp={onKeyUp}
        value={value}
        isFullWidth
      />
    </MultiSearchFormStyled>
  );
};

export default MultiSearchForm;
