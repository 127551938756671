import { RootState } from 'store/rootReducer';

export const getCompanySelectAthenaResults = (state: RootState) =>
  state.companySelect.results.athena;
export const getCompanySelectCHResults = (state: RootState) =>
  state.companySelect.results.companiesHouse;
export const getCompanySelectIsFetching = (state: RootState) =>
  state.companySelect.isFetching;
export const getCompanySelectSearchTerm = (state: RootState) =>
  state.companySelect.searchTerm;
export const getCompanySelectPageNumber = (state: RootState) =>
  state.companySelect.results.athena.meta?.page;
export const getCompanySelectTotalPages = (state: RootState) =>
  state.companySelect.results.athena.meta?.pages;
