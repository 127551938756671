import React from 'react';
import { QuickSearchToggleButtonStyled } from './QuickSearchToggleStyled';

interface QuickSearchToggleButtonProps {
  label: string;
  onClick: () => void;
  isActive?: boolean;
  left?: boolean;
}

const QuickSearchToggleButton: React.FC<QuickSearchToggleButtonProps> = ({
  onClick,
  label,
  children,
  isActive,
  left,
}) => {
  return (
    <QuickSearchToggleButtonStyled
      onClick={onClick}
      active={isActive}
      left={left}
    >
      {label}
      {children}
    </QuickSearchToggleButtonStyled>
  );
};

export default QuickSearchToggleButton;
