/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/CallToActions/Button/Button';
import {
  GeneralModalTitle,
  ModalCardHeader,
  ModalCardMain,
  ModalCardWrapper,
} from 'components/ModalCard/ModalCard';
import GlobalModalWrapper from 'connected/GlobalModal/GlobalModalWrapper';

import { globalSingleModalHide } from 'store/actions/globalModalActions';
import {
  getCurrentModalSelector,
  getModalIsOpenSelector,
} from 'store/selectors/globalModalSelectors';

import { MODAL_ID } from 'connected/GlobalModal/constants';
import { ModalPromptActions } from 'components/ModalPrompt/ModalPromptStyled';
import { saveAthenaUserViewedReleaseNotesVersion } from 'store/actions/athenaUserActions';
import { InfoIcon, ReleaseNotesBodyWrapper } from './ReleaseNotesStyled';
import { RELEASE_NOTES_COPY } from './constants';

interface ReleaseNotesModalProps {
  athenaFriendlyVersion: string;
  releaseNotesBody: string;
}

const ReleaseNotesModal: React.FC<ReleaseNotesModalProps> = ({
  athenaFriendlyVersion,
  releaseNotesBody,
}: ReleaseNotesModalProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getModalIsOpenSelector);
  const currentModal = useSelector(getCurrentModalSelector);
  const isModalVisible = isOpen && currentModal === MODAL_ID.RELEASE_NOTES;

  useEffect(() => {
    if (isModalVisible) {
      dispatch(
        saveAthenaUserViewedReleaseNotesVersion({
          version: athenaFriendlyVersion,
        }),
      );
    }
  }, [dispatch, athenaFriendlyVersion, isModalVisible]);

  const onConfirm = () =>
    dispatch(globalSingleModalHide(MODAL_ID.RELEASE_NOTES));

  return (
    <>
      {isModalVisible && (
        <GlobalModalWrapper
          modalId={MODAL_ID.RELEASE_NOTES}
          hasCloseButton={false}
          lightTheme
          isClickableOutsideToClose={false}
          size="large"
        >
          <ModalCardWrapper>
            <ModalCardHeader>
              <InfoIcon />
              <GeneralModalTitle>
                {RELEASE_NOTES_COPY.modalTitle}
              </GeneralModalTitle>
            </ModalCardHeader>
            <ModalCardMain>
              <ReleaseNotesBodyWrapper>
                <p dangerouslySetInnerHTML={{ __html: releaseNotesBody }} />
              </ReleaseNotesBodyWrapper>
              <ModalPromptActions actionError>
                <Button onClick={() => onConfirm()}>
                  {RELEASE_NOTES_COPY.btn.ok}
                </Button>
              </ModalPromptActions>
            </ModalCardMain>
          </ModalCardWrapper>
        </GlobalModalWrapper>
      )}
    </>
  );
};

export default ReleaseNotesModal;
