import {
  AddEpcAction,
  AddEpcActionTypes,
  AddEpcFailAction,
  AddEpcSuccessAction,
} from 'connected/EpcCertificatesPanel/AddEpc/types';
import {
  UnassignEpcAction,
  UnassignEpcActionTypes,
  UnassignEpcFailAction,
  UnassignEpcSuccessAction,
} from 'connected/EpcCertificatesPanel/UnassignEpc/types';
import { GlobalApiFailError } from './types';

// ADD EPC
export const addEpc = (epcIds: string[], buildingId: string): AddEpcAction => ({
  type: AddEpcActionTypes.ADD_EPC,
  payload: { epcIds, buildingId },
});

export const addEpcSuccess = (
  epcIds: string[],
  buildingId: string,
): AddEpcSuccessAction => ({
  type: AddEpcActionTypes.ADD_EPC_SUCCESS,
  payload: { epcIds, buildingId },
});

export const addEpcFail = (payload: GlobalApiFailError): AddEpcFailAction => ({
  type: AddEpcActionTypes.ADD_EPC_FAIL,
  payload,
});

// UNASSIGN EPC
export const unassignEpc = (
  epcId: string,
  buildingId: string,
): UnassignEpcAction => ({
  type: UnassignEpcActionTypes.UNASSIGN_EPC,
  payload: { epcId, buildingId },
});

export const unassignEpcSuccess = (
  epcId: string,
  buildingId: string,
): UnassignEpcSuccessAction => ({
  type: UnassignEpcActionTypes.UNASSIGN_EPC_SUCCESS,
  payload: { epcId, buildingId },
});

export const unassignEpcFail = (
  payload: GlobalApiFailError,
): UnassignEpcFailAction => ({
  type: UnassignEpcActionTypes.UNASSIGN_EPC_FAIL,
  payload,
});
