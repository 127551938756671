import { SortTypes, TableHeaderCellProps } from 'components/Table/types';
import { Vertical } from 'globalConstants';
import { DownloadableVertical } from './types';

export const DEFAULT_PAGINATION_OPTIONS = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '50',
    value: '50',
  },
];

export const ERROR_MODAL_ID = 'downloadThresholdErrorModal';

export const MAX_SINGLE_EXPORT_SIZE: Record<DownloadableVertical, number> = {
  toLet: 250,
  lease: 500,
  building: 250,
  sale: 50,
  portfolioSale: 50,
};

export const DAILY_DOWNLOAD_LIMITS: Record<DownloadableVertical, number> = {
  toLet: MAX_SINGLE_EXPORT_SIZE.toLet * 3,
  lease: MAX_SINGLE_EXPORT_SIZE.lease * 3,
  building: MAX_SINGLE_EXPORT_SIZE.building * 3,
  sale: MAX_SINGLE_EXPORT_SIZE.sale * 3,
  portfolioSale: MAX_SINGLE_EXPORT_SIZE.portfolioSale * 3,
};

export enum ResultView {
  List = 'list',
  Map = 'map',
}

export const RESULT_COLUMNS: { [key in 'default' | Vertical]: number } = {
  sale: 10,
  lease: 10,
  toLet: 10,
  building: 2,
  company: 2,
  default: 10,
  portfolioSale: 10,
};

export const PRIMARY_IMAGE_HEADER = {
  title: 'Primary Image',
  isFixed: false,
  isVisible: true,
  key: 'primaryImage',
};

const BASIC_HEADERS = [
  {
    title: 'Property',
    isSortable: {
      type: SortTypes.string,
      key: 'salesSortingAddress',
    },
    isFixed: true,
    isVisible: true,
    key: 'property',
  },
  {
    title: 'Property',
    isFixed: true,
    isExportOnly: true,
    isVisible: true,
    key: 'propertyAddress',
  },
  PRIMARY_IMAGE_HEADER,
  {
    title: 'Confidential',
    isSortable: {
      type: SortTypes.string,
      key: 'sales.dealIsPrivate',
    },
    isFixed: false,
    isVisible: true,
    key: 'private',
  },
];

export const RESULT_HEADERS: Record<Vertical, TableHeaderCellProps[]> = {
  sale: [
    ...BASIC_HEADERS,
    {
      title: 'Buyers',
      isFixed: true,
      isVisible: true,
      isExportOnly: true,
      key: 'buyers',
    },
    {
      title: 'Sellers',
      isFixed: true,
      isVisible: true,
      isExportOnly: true,
      key: 'sellers',
    },
    {
      title: 'Status',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.availabilityStatus',
      },
      isFixed: false,
      isVisible: true,
      key: 'status',
    },
    {
      title: 'Date',
      isSortable: {
        type: SortTypes.date,
        key: 'salesSortingDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'date',
    },
    {
      title: 'Total Area (sq ft) at Sale',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.totalAreaAtSale',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalArea',
    },
    {
      title: 'Total Rent',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.rentalIncome',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalRent',
    },
    {
      title: 'WAULTC at Transaction',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.weightedAverageUnexpiredLeaseTermCertain',
      },
      isFixed: false,
      isVisible: true,
      key: 'WAULTC',
    },
    {
      title: 'NIY',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.netInitialYield',
      },
      isFixed: false,
      isVisible: true,
      key: 'NIY',
      childrenVisible: false,
      children: [
        {
          title: 'EY',
          isSortable: {
            type: SortTypes.numeric,
            key: 'sales.equivalentYield',
          },
          isFixed: false,
          isVisible: true,
          key: 'EY',
        },
        {
          title: 'RY',
          isSortable: {
            type: SortTypes.numeric,
            key: 'sales.reversionaryYield',
          },
          isFixed: false,
          isVisible: true,
          key: 'RY',
        },
      ],
    },
    {
      title: 'Price',
      isSortable: {
        type: SortTypes.numeric,
        key: 'salesSortingPrice',
      },
      isFixed: false,
      isVisible: true,
      key: 'price',
      childrenVisible: false,
      children: [
        {
          title: 'Purchasers Costs',
          isSortable: {
            type: SortTypes.numeric,
            key: 'sales.purchaserCosts',
          },
          isFixed: false,
          isVisible: true,
          key: 'purchasersCosts',
        },
      ],
    },
    {
      title: 'Capital Value',
      isSortable: {
        type: SortTypes.numeric,
        key: 'salesCapitalValue',
      },
      isFixed: false,
      isVisible: true,
      key: 'capitalValue',
    },
    {
      title: 'Tenure',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.tenure',
      },
      isFixed: false,
      isVisible: true,
      key: 'tenure',
    },
    {
      title: 'Asset Class',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.primaryUse',
      },
      isFixed: false,
      isVisible: true,
      key: 'assetClass',
    },
    {
      title: 'Sale Type',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.ownerOccupierPurchase',
      },
      isFixed: false,
      isVisible: true,
      key: 'saleType',
    },
    {
      title: 'Disputed',
      isFixed: false,
      isVisible: true,
      key: 'disputed',
    },
    {
      title: 'Documents',
      isFixed: false,
      isVisible: true,
      key: 'docsCount',
    },
    {
      title: 'EPC Rating',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.epcRating',
      },
      isFixed: false,
      isVisible: true,
      key: 'epcRating',
    },
    {
      title: 'Link',
      isFixed: true,
      isVisible: true,
      isExportOnly: true,
      key: 'link',
    },
  ],
  portfolioSale: [
    {
      title: 'Portfolio Name',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.portfolioName',
      },
      isFixed: true,
      isVisible: true,
      key: 'portfolioName',
    },
    {
      title: 'Confidential',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.dealIsPrivate',
      },
      isFixed: false,
      isVisible: true,
      key: 'private',
    },
    {
      title: 'Number of Buildings',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.numberOfBuildings',
      },
      isFixed: false,
      isVisible: true,
      key: 'numberOfBuildings',
    },
    {
      title: 'Status',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.availabilityStatus',
      },
      isFixed: false,
      isVisible: true,
      key: 'status',
    },
    {
      title: 'Date',
      isSortable: {
        type: SortTypes.date,
        key: 'salesSortingDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'date',
    },
    {
      title: 'Total Area (sq ft) at Sale',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.totalAreaAtSale',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalArea',
    },
    {
      title: 'Total Rent',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.rentalIncome',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalRent',
    },
    {
      title: 'WAULTC at Transaction',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.weightedAverageUnexpiredLeaseTermCertain',
      },
      isFixed: false,
      isVisible: true,
      key: 'WAULTC',
    },
    {
      title: 'NIY',
      isSortable: {
        type: SortTypes.numeric,
        key: 'sales.netInitialYield',
      },
      isFixed: false,
      isVisible: true,
      key: 'NIY',
      childrenVisible: false,
      children: [
        {
          title: 'EY',
          isSortable: {
            type: SortTypes.numeric,
            key: 'sales.equivalentYield',
          },
          isFixed: false,
          isVisible: true,
          key: 'EY',
        },
        {
          title: 'RY',
          isSortable: {
            type: SortTypes.numeric,
            key: 'sales.reversionaryYield',
          },
          isFixed: false,
          isVisible: true,
          key: 'RY',
        },
      ],
    },
    {
      title: 'Price',
      isSortable: {
        type: SortTypes.numeric,
        key: 'salesSortingPrice',
      },
      isFixed: false,
      isVisible: true,
      key: 'price',
      childrenVisible: false,
      children: [
        {
          title: 'Purchasers Costs',
          isSortable: {
            type: SortTypes.numeric,
            key: 'sales.purchaserCosts',
          },
          isFixed: false,
          isVisible: true,
          key: 'purchasersCosts',
        },
      ],
    },
    {
      title: 'Capital Value',
      isSortable: {
        type: SortTypes.numeric,
        key: 'salesCapitalValue',
      },
      isFixed: false,
      isVisible: true,
      key: 'capitalValue',
    },
    {
      title: 'Tenure',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.tenure',
      },
      isFixed: false,
      isVisible: true,
      key: 'tenure',
    },
    {
      title: 'Sale Type',
      isSortable: {
        type: SortTypes.string,
        key: 'sales.ownerOccupierPurchase',
      },
      isFixed: false,
      isVisible: true,
      key: 'saleType',
    },
    {
      title: 'Disputed',
      isFixed: false,
      isVisible: true,
      key: 'disputed',
    },
    {
      title: 'Documents',
      isFixed: false,
      isVisible: true,
      key: 'docsCount',
    },
  ],
  company: [
    {
      title: '',
      isSortable: {
        type: SortTypes.string,
      },
      isFixed: false,
      isVisible: true,
      key: '',
    },
    {
      title: 'Disputed',
      isFixed: false,
      isVisible: true,
      key: 'disputed',
    },
  ],
  lease: [
    ...BASIC_HEADERS,
    {
      title: 'Primary Use',
      isSortable: {
        type: SortTypes.string,
        key: 'leases.primaryUse',
      },
      isFixed: false,
      isVisible: true,
      key: 'primaryUse',
    },
    {
      title: 'Tenant',
      isSortable: {
        type: SortTypes.string,
        key: 'leaseTenantCompany',
      },
      isFixed: false,
      isVisible: true,
      key: 'tenant',
      childrenVisible: false,
      children: [
        {
          title: 'Tenant Broad Sector',
          isSortable: {
            type: SortTypes.string,
            key: 'leaseTenantBroadSector',
          },
          isFixed: false,
          isVisible: true,
          key: 'tenantBroadSector',
        },
        {
          title: 'Tenant Nationality',
          isSortable: {
            type: SortTypes.string,
            key: 'leaseTenantNationality',
          },
          isFixed: false,
          isVisible: true,
          key: 'tenantNationality',
        },
        {
          title: 'Tenant Equivalent Bond Rating',
          isSortable: {
            type: SortTypes.string,
            key: 'leaseTenantEquivalentBondRating',
          },
          isFixed: false,
          isVisible: true,
          key: 'tenantEquivalentBondRating',
        },
        {
          title: 'Tenant Incans Global Score',
          isSortable: {
            type: SortTypes.string,
            key: 'leaseTenantIncansGlobalScore',
          },
          isFixed: false,
          isVisible: true,
          key: 'tenantIncansGlobalScore',
        },
        {
          title: "Tenant's Agent",
          isSortable: {
            type: SortTypes.string,
            key: 'leaseTenantAgent',
          },
          isFixed: false,
          isVisible: true,
          key: 'tenantAgents',
        },
      ],
    },
    {
      title: 'Floor',
      isSortable: {
        type: SortTypes.string,
        key: 'leaseFloor',
      },
      isFixed: false,
      isVisible: true,
      key: 'floor',
    },
    {
      title: 'Area (sq ft)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leaseParentTotalArea',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalArea',
    },
    {
      title: 'Start Date',
      isSortable: {
        type: SortTypes.date,
        key: 'leaseStartDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'startDate',
    },
    {
      title: 'Next Rent Review',
      isSortable: {
        type: SortTypes.date,
        key: 'leaseNextRentReviewDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'nextRentReview',
    },
    {
      title: 'Next Break',
      isSortable: {
        type: SortTypes.date,
        key: 'leaseNextBreakDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'nextBreak',
    },
    {
      title: 'End Date',
      isSortable: {
        type: SortTypes.date,
        key: 'leaseEndDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'endDate',
    },
    {
      title: 'Next Lease Event',
      isSortable: {
        type: SortTypes.date,
        key: 'leaseNextEventDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'nextLeaseEvent',
    },
    {
      title: 'Lease Length',
      isSortable: {
        type: SortTypes.date,
        key: 'leaseLength',
      },
      isFixed: false,
      isVisible: true,
      key: 'leaseLength',
    },
    {
      title: 'Offered Rent (per sq ft)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leaseOfferedRent',
      },
      isFixed: false,
      isVisible: true,
      key: 'offeredRent',
    },
    {
      title: 'Weighted Average Offered Rent (per sq ft)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leaseWeightedAverageOfferedRent',
      },
      isFixed: false,
      isVisible: true,
      key: 'weightedAverageOfferedRent',
    },
    {
      title: 'Achieved Rent (per sq ft)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leaseAchievedRent',
      },
      isFixed: false,
      isVisible: true,
      key: 'achievedRent',
    },
    {
      title: 'Weighted Average Achieved Rent (per sq ft)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leaseWeightedAverageAchievedRent',
      },
      isFixed: false,
      isVisible: true,
      key: 'weightedAverageAchievedRent',
    },
    {
      title: 'Annual Rent',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leaseTotalRent',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalRent',
      childrenVisible: false,
      children: [
        {
          title: 'Rent Free (months)',
          isSortable: {
            type: SortTypes.numeric,
            key: 'leaseRentFreeMonthsTotal',
          },
          isFixed: false,
          isVisible: true,
          key: 'rentFree',
        },
        {
          title: 'Capital Contribution',
          isSortable: {
            type: SortTypes.numeric,
            key: 'leaseCapitalContribution',
          },
          isFixed: false,
          isVisible: true,
          key: 'capitalContribution',
        },
      ],
    },
    {
      title: 'Lease Type',
      isSortable: {
        type: SortTypes.string,
        key: 'leaseLeaseType',
      },
      isFixed: false,
      isVisible: true,
      key: 'leaseType',
    },
    {
      title: 'Outside 54 Act',
      isSortable: {
        type: SortTypes.string,
        key: 'leaseOutsideAct54',
      },
      isFixed: false,
      isVisible: true,
      key: 'act54',
    },
    {
      title: 'Title Number',
      isSortable: {
        type: SortTypes.string,
        key: 'leaseTitleNumber',
      },
      isFixed: false,
      isVisible: true,
      key: 'titleNumber',
    },
    {
      title: 'Landlord (at Transaction)',
      isSortable: {
        type: SortTypes.string,
        key: 'leaseLandlordAtTx',
      },
      isFixed: false,
      isVisible: true,
      key: 'landlordAtTx',
      childrenVisible: false,
      children: [
        {
          title: 'Landlord (at Transaction) Equivalent bond rating',
          isSortable: {
            type: SortTypes.string,
            key: 'leaseLandlordAtTxEquivalentBondRating',
          },
          isFixed: false,
          isVisible: true,
          key: 'landlordAtTxEquivalentBondRating',
        },
        {
          title: 'Landlord (at Transaction) Incans global score',
          isSortable: {
            type: SortTypes.string,
            key: 'leaseLandlordAtTxIncansGlobalScore',
          },
          isFixed: false,
          isVisible: true,
          key: 'landlordAtTxIncansGlobalScore',
        },
      ],
    },
    {
      title: "Landlord's Agent",
      isSortable: {
        type: SortTypes.string,
        key: 'leaseLandlordAgent',
      },
      isFixed: false,
      isVisible: true,
      key: 'landlordAgents',
    },
    {
      title: 'Disputed',
      isFixed: false,
      isVisible: true,
      key: 'disputed',
    },
    {
      title: 'Documents',
      isFixed: false,
      isVisible: true,
      key: 'docsCount',
    },
    {
      title: 'EPC Rating',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.epcRating',
      },
      isFixed: false,
      isVisible: true,
      key: 'epcRating',
    },
    {
      title: 'Notes',
      isExportOnly: true,
      isVisible: true,
      key: 'notes',
    },
    {
      title: 'Landlord (as at today)',
      isVisible: true,
      key: 'landlordToday',
      childrenVisible: false,
      children: [
        {
          title: 'Landlord (as at today) Equivalent bond rating',
          isFixed: false,
          isVisible: true,
          key: 'landlordTodayEquivalentBondRating',
        },
        {
          title: 'Landlord (as at today) Incans global score',
          isFixed: false,
          isVisible: true,
          key: 'landlordTodayIncansGlobalScore',
        },
      ],
    },
    {
      title: 'Link',
      isFixed: true,
      isVisible: true,
      isExportOnly: true,
      key: 'link',
    },
  ],
  building: [
    {
      title: 'Property',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.postcode',
      },
      isFixed: true,
      isVisible: true,
      key: 'property',
    },
    PRIMARY_IMAGE_HEADER,
    {
      title: 'Primary Use',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.primaryUse',
      },
      isFixed: false,
      isVisible: true,
      key: 'assetClass',
    },
    {
      title: 'Planning Permitted Uses',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.primaryUseSubCategories',
      },
      isFixed: false,
      isVisible: true,
      key: 'primaryUseSubCategories',
    },
    {
      title: 'Investment Sub Categories',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.investmentSubCategories',
      },
      isFixed: false,
      isVisible: true,
      key: 'investmentSubCategories',
    },
    {
      title: 'Total area',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildings.totalArea',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalArea',
    },
    {
      title: 'Total Annual Rent (at last transaction)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'latestSaleRentalIncome',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalRentalIncome',
    },
    {
      title: 'Total Annual Rent (as of now)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildingAnnualRent',
      },
      isFixed: false,
      isVisible: true,
      key: 'annualRent',
    },
    {
      title: 'Average rent (per sq ft)',
      isSortable: {
        type: SortTypes.numeric,
        key: 'averageRent',
      },
      isFixed: false,
      isVisible: true,
      key: 'averageRent',
    },
    {
      title: 'WAULTC as at Today',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildings.waultc',
      },
      isFixed: false,
      isVisible: true,
      key: 'WAULTC',
      childrenVisible: false,
      children: [
        {
          title: 'WAULT as at Today',
          isSortable: {
            type: SortTypes.numeric,
            key: 'buildings.wault',
          },
          isFixed: false,
          isVisible: true,
          key: 'wault',
        },
      ],
    },
    {
      title: 'Number of known tenants',
      isSortable: {
        type: SortTypes.numeric,
        key: 'knownTenants',
      },
      isFixed: false,
      isVisible: true,
      key: 'knownTenants',
    },
    {
      title: 'Number of floors',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildings.totalFloors',
      },
      isFixed: false,
      isVisible: true,
      key: 'floors',
    },
    {
      title: 'Building age',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildingAge',
      },
      isFixed: false,
      isVisible: true,
      key: 'buildingAge',
    },
    {
      title: 'Last sale',
      isSortable: {
        type: SortTypes.date,
        key: 'latestSaleDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'lastSale',
    },
    {
      title: 'Landlord',
      isSortable: {
        type: SortTypes.string,
        key: 'buildingLandlord',
      },
      isFixed: false,
      isVisible: true,
      key: 'landlord',
    },
    {
      title: 'Tenure',
      isSortable: {
        type: SortTypes.string,
        key: 'buildingTenure',
      },
      isFixed: false,
      isVisible: true,
      key: 'tenure',
    },
    {
      title: 'Lender(s)',
      isSortable: {
        type: SortTypes.string,
        key: 'buildingDebtLenders',
      },
      isFixed: false,
      isVisible: true,
      key: 'lender',
    },
    {
      title: 'Facility Start Date',
      isSortable: {
        type: SortTypes.date,
        key: 'buildingDebtStartDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'debtStartDate',
    },
    {
      title: 'Debt Maturity Date',
      isSortable: {
        type: SortTypes.date,
        key: 'buildingDebtMaturityDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'debtMaturityDate',
    },
    {
      title: 'Total Current Debt',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildingTotalDebt',
      },
      isFixed: false,
      isVisible: true,
      key: 'totalCurrentDebt',
    },
    {
      title: 'Debt Interest Rate',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildingDebtInterestRate',
      },
      isFixed: false,
      isVisible: true,
      key: 'debtInterestRate',
    },
    {
      title: 'Last Valuation',
      isSortable: {
        type: SortTypes.numeric,
        key: 'buildingLatestValuationAmount',
      },
      isFixed: false,
      isVisible: true,
      key: 'latestValuation',
    },
    {
      title: 'Aggregated EPC rating',
      isSortable: {
        type: SortTypes.string,
        key: 'buildings.epcRating',
      },
      isFixed: false,
      isVisible: true,
      key: 'epcRating',
    },
    {
      title: 'Notes',
      isExportOnly: true,
      isVisible: true,
      key: 'notes',
    },
    {
      title: 'Link',
      isFixed: true,
      isVisible: true,
      isExportOnly: true,
      key: 'link',
    },
  ],
  toLet: [
    {
      title: 'Property',
      isSortable: {
        type: SortTypes.string,
        key: 'toLetPostcode',
      },
      isFixed: true,
      isVisible: true,
      key: 'property',
    },
    PRIMARY_IMAGE_HEADER,
    {
      title: 'Lease Type',
      isSortable: {
        type: SortTypes.string,
        key: 'leases.type',
      },
      isVisible: true,
      key: 'leaseType',
    },
    {
      title: 'Primary Use',
      isSortable: {
        type: SortTypes.string,
        key: 'leases.primaryUse',
      },
      isVisible: true,
      key: 'primaryUse',
    },
    {
      title: 'Use Clause',
      isSortable: {
        type: SortTypes.string,
        key: 'leases.useClauses',
      },
      isVisible: true,
      key: 'useClause',
    },
    {
      title: 'Asking Rent',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leases.askingRent',
      },
      isVisible: true,
      key: 'askingRent',
    },
    {
      title: 'Min Sq Ft',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leases.minimumLettableArea',
      },
      isFixed: false,
      isVisible: true,
      key: 'minSqFt',
    },
    {
      title: 'Max Sq Ft',
      isSortable: {
        type: SortTypes.numeric,
        key: 'leases.maximumLettableArea',
      },
      isFixed: false,
      isVisible: true,
      key: 'maxSqFt',
    },
    {
      title: 'Ready to Occupy Date',
      isSortable: {
        type: SortTypes.date,
        key: 'leases.readyToOccupyDate',
      },
      isFixed: false,
      isVisible: true,
      key: 'readyToOccupy',
    },
    {
      title: 'Landlord',
      isSortable: {
        type: SortTypes.string,
        key: 'crmleaseLandlordCompany.name',
      },
      isFixed: false,
      isVisible: true,
      key: 'landlord',
    },
    {
      title: "Landlord's Agent",
      isSortable: {
        type: SortTypes.string,
        key: 'crmleaseLandlordAgentCompany.name',
      },
      isFixed: false,
      isVisible: true,
      key: 'landlordAgents',
    },
    {
      title: 'Disputed',
      isFixed: false,
      isVisible: true,
      key: 'disputed',
    },
    {
      title: 'Documents',
      isFixed: false,
      isVisible: true,
      key: 'docsCount',
    },
    {
      title: 'Link',
      isFixed: true,
      isVisible: true,
      isExportOnly: true,
      key: 'link',
    },
  ],
};

export const SEARCH_RESULTS_COPY = {
  noResults: 'Sorry there were no results found',
  expand: 'Expand all',
  collapse: 'Collapse all',
  mapBuildingsLoading: 'Building Pins Loading...',
  fetchMoreMapPins: 'Not all results displayed, fetch another 200',
  mapViewCard: {
    seeMore: 'See Details',
    closeTable: 'Close Details',
    recordLabels: {
      [Vertical.Lease]: 'Lease records:',
      [Vertical.ToLet]: 'To Let records:',
      [Vertical.Sale]: 'Sale records:',
      [Vertical.PortfolioSale]: 'Sale records:',
      [Vertical.Building]: 'Building records:',
      [Vertical.Company]: 'Company records:',
    },
    totalAreaLabel: 'Total Area:',
    averageRentLabel: 'Average Rent:',
    totaAreaSuffix: 'sq ft',
    averageRentSuffix: 'per sq ft',
  },
  modalErrorThresholdTitle: 'Export Threshold Reached',
  modalErrorThresholdMessage:
    "You have reached today's threshold for this type of export. If you require more information than this export limit allowed please contact the Insight & Data team for assistance.",
  modalErrorThresholdBtnOK: 'OK',
  headlineStatistics: 'Headline Statistics',
};
