import { GlobalApiFailError } from 'store/actions/types';

export enum UnassignEpcActionTypes {
  UNASSIGN_EPC = 'UNASSIGN_EPC',
  UNASSIGN_EPC_SUCCESS = 'UNASSIGN_EPC_SUCCESS',
  UNASSIGN_EPC_FAIL = 'UNASSIGN_EPC_FAIL',
}

export type UnassignEpcActionPayload = {
  epcId: string;
  buildingId: string;
};

export type UnassignEpcAction = {
  type: UnassignEpcActionTypes.UNASSIGN_EPC;
  payload: UnassignEpcActionPayload;
};

export type UnassignEpcSuccessAction = {
  type: UnassignEpcActionTypes.UNASSIGN_EPC_SUCCESS;
  payload: UnassignEpcActionPayload;
};

export type UnassignEpcFailAction = {
  type: UnassignEpcActionTypes.UNASSIGN_EPC_FAIL;
  payload: GlobalApiFailError;
};

export type UnassignEpcActions =
  | UnassignEpcAction
  | UnassignEpcSuccessAction
  | UnassignEpcFailAction;
