import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import compose from 'ramda/src/compose';
import { desaturate, darken, transparentize } from 'polished';
import { IconPropTypes } from 'components/Icon/Icon';
import { ReactComponent as ChevronDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import { ReactComponent as PDF } from 'assets/icons/pdf.svg';
import { ReactComponent as Spreadsheet } from 'assets/icons/spreadsheet.svg';
import { ReactComponent as View } from 'assets/icons/view.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Confidential } from 'assets/icons/confidential.svg';
import { ReactComponent as Canonical } from 'assets/icons/canonical-address.svg';
import { ReactComponent as Hidden } from 'assets/icons/hidden.svg';
import { PanelTheme } from 'components/PanelWrapper/types';

const withPrimaryUseIconStyles = (IconSVG: React.FC) => styled(IconSVG)<
  IconPropTypes
>`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  height: 24px;
  width: 24px;
`;

const withSortStyled = (IconSVG: React.FC) => styled(IconSVG)<IconPropTypes>`
  height: 18px;
  width: 18px;
  position: absolute;
  left: -5px;
`;

const withInlineIconStyles = (IconSVG: React.FC) => styled(IconSVG)<
  IconPropTypes
>`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  position: relative;
  top: 0;
  margin-right: 10px;
  height: 16px;
  width: 16px;
`;

const getHighlightStyles = (theme: any, hasHighlight?: boolean) => {
  if (hasHighlight) {
    return css`
      color: ${theme.colorPalette.secondary.red};
    `;
  }
  return ``;
};
export const TableCellStyled = styled.td<{
  textAlign?: string;
  hasHighlight?: boolean;
  isIconCell?: boolean;
}>`
  width: ${(props) => (props.isIconCell ? 'auto' : '1000px')};
  padding: 10px 5px;
  border: 0;
  word-break: break-word;
  text-align: ${(props) => props.textAlign || 'left'};
  border-bottom: 2px solid ${(props) => props.theme.colorPalette.branding.white};

  ${(props) => getHighlightStyles(props.hasHighlight)}

  &:first-child {
    position: sticky;
    z-index: 10;
  }
`;

export const EditCellModalWrapper = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

export const ActionCellWrapper = styled.span<{ isIconCell?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isIconCell ? 'flex-start' : 'flex-end')};
  padding: 0;
`;

const getWrappedStyles = (shouldWrap?: boolean) => {
  if (shouldWrap) {
    return css`
      flex-wrap: wrap;
    `;
  }
  return ``;
};

export const StyledHeadingCell = styled.span<{
  isBold: boolean;
  shouldWrap?: boolean;
}>`
  display: flex;
  position: relative;
  margin: 2px 0;
  padding-right: 20px;

  > span {
    display: inline-block;
    font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  }
  li {
    padding: 2px 0;
  }

  ${(props) => getWrappedStyles(props.shouldWrap)}
`;

const getWidthStyles = (
  columns: number,
  isWide: boolean,
  isHeadingHidden: boolean,
) => {
  if (isWide) {
    return css`
      width: calc((100% / ${columns}) * 2);
    `;
  }

  if (isHeadingHidden) {
    return css`
      width: 30px;
    `;
  }

  return css`
    width: calc(100% / ${columns});
  `;
};

export const TableHeaderStyled = styled.th<{
  useTheme: PanelTheme;
  columns: number;
  isWide: boolean;
  isHeadingHidden: boolean;
}>`
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
  text-align: left;
  font-weight: 700;
  background: ${(props) =>
    compose(
      desaturate(0.2),
      darken(0.05),
    )(props.theme.colorPalette.panel[props.useTheme])};
  border: 0;
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
  ${(props) =>
    getWidthStyles(props.columns, props.isWide, props.isHeadingHidden)}
`;

const getChildrenExpandedStyles = (
  theme: any,
  hasChildrenExpanded: boolean,
  useWhiteBackground: boolean,
) => {
  if (hasChildrenExpanded && useWhiteBackground) {
    return css`
      border: 1px dashed ${theme.secondary.lightGrey};
      border-right: 1px solid ${theme.branding.white};
      background: ${theme.branding.white};
    `;
  }
  if (hasChildrenExpanded && !useWhiteBackground) {
    return css``;
  }

  return ``;
};

const getChildStyles = (
  theme: any,
  isChild: boolean,
  useWhiteBackground: boolean,
) => {
  if (isChild && useWhiteBackground) {
    return css`
      justify-content: 'space-between';
      border: 1px dashed ${theme.secondary.lightGrey};
      border-left: 1px solid ${theme.branding.white};
      background: ${theme.branding.white};
    `;
  }

  if (isChild && !useWhiteBackground) {
    return css`
      justify-content: 'space-between';
    `;
  }

  return css`
    justify-content: 'flex-start';
  `;
};

export const getLastChildStyles = (
  theme: any,
  isNotLastChild: boolean,
  useWhiteBackground: boolean,
) => {
  if (isNotLastChild && useWhiteBackground) {
    return css`
      border-right: 1px solid ${theme.branding.white};
    `;
  }
  return ``;
};

export const TableHeading = styled.span<{
  isHidden: boolean;
  isChild: boolean;
  isNotLastChild: boolean;
  hasChildrenExpanded: boolean;
  useWhiteBackground: boolean;
}>`
  padding: 0 5px;
  min-height: 35px;
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  align-items: center;
  ${(props) =>
    getChildStyles(
      props.theme.colorPalette,
      props.isChild,
      props.useWhiteBackground,
    )}
  ${(props) =>
    getChildrenExpandedStyles(
      props.theme.colorPalette,
      props.hasChildrenExpanded,
      props.useWhiteBackground,
    )}
  ${(props) =>
    getLastChildStyles(
      props.theme.colorPalette,
      props.isNotLastChild,
      props.useWhiteBackground,
    )}
`;

export const TableRowStyled = styled.tr`
  height: 48px;
  th {
    &:first-child {
      padding-left: 10px;
    }
  }
  td {
    &:first-child {
      padding-left: 15px;
    }
  }
`;

export const TableStyled = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
`;

export const SortButtonStyled = styled.span`
  padding: 10px 0;
  text-transform: none;
  font-weight: 700;
  position: relative;
  display: inline-block;
`;

export const SortIconStyled = styled.span<{ disabled: boolean }>`
  display: flex;
  padding: 0;
  opacity: ${(props) => (props.disabled ? '0.25' : '1')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export const SortAscIcon = styled(withSortStyled(ChevronUp))`
  top: -4px;
`;
export const SortDescIcon = styled(withSortStyled(ChevronDown))`
  bottom: -4px;
`;

export const CurrentSortHeader = styled.span`
  text-decoration: underline;
`;

export const SortHeader = styled.span<{ isActive: boolean }>`
  cursor: pointer;
  text-decoration: ${(props) => (props.isActive ? 'underline' : 'none')};
  display: inline-block;
  width: 100%;
  text-align: left;
`;

export const ActionButton = styled.button`
  align-self: center;
  &:disabled {
    cursor: default;
  }
`;

export const InlineActionButton = styled(ActionButton)`
  text-align: left;
  padding: 10px;
  width: 100%;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  color: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const ActionSpan = styled.span`
  align-self: center;
`;

export const ActionLink = styled(Link)`
  align-self: center;
  pointer-events: all;
  color: ${(props) => props.theme.colorPalette.branding.ink};
  &:disabled {
    cursor: default;
  }
`;

export const InlineActionLink = styled(ActionLink)`
  text-align: left;
  padding: 10px;
  width: 100%;
  font-weight: bold;
`;

export const ActionTextLink = styled(ActionLink)`
  text-decoration: underline;
`;

const DownloadStyled = withPrimaryUseIconStyles(Download);
export const DownloadButtonIcon = styled(DownloadStyled)``;

const DeleteStyled = withPrimaryUseIconStyles(Delete);
export const DeleteButtonIcon = styled(DeleteStyled)`
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;

  &:hover,
  &:focus {
    fill: ${(props) => props.theme.colorPalette.secondary.red};
  }
`;

const LockStyled = withPrimaryUseIconStyles(Lock);
export const LockButtonIcon = styled(LockStyled)`
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;

  &:hover,
  &:focus {
    fill: ${(props) => props.theme.colorPalette.secondary.red};
  }
`;

const ViewStyled = withPrimaryUseIconStyles(View);
export const ViewButtonIcon = styled(ViewStyled)``;

const ImageStyled = withInlineIconStyles(Image);
export const ImageIcon = styled(ImageStyled)``;

const DocumentStyled = withInlineIconStyles(Document);
export const DocumentIcon = styled(DocumentStyled)``;

const HiddenIconStyled = withInlineIconStyles(Hidden);
export const HiddenIcon = styled(HiddenIconStyled)`
  margin-top: 1px;
`;

const PDFStyled = withInlineIconStyles(PDF);
export const PDFIcon = styled(PDFStyled)``;

const SpreadsheetStyled = withInlineIconStyles(Spreadsheet);
export const SpreadsheetIcon = styled(SpreadsheetStyled)``;

const WarningStyled = withInlineIconStyles(Warning);
export const WarningButtonIcon = styled(WarningStyled)`
  fill: ${(props) => props.theme.colorPalette.tertiary.datavizOrange};
`;

const ConfidentialStyled = withInlineIconStyles(Confidential);

const CanonicalStyled = withInlineIconStyles(Canonical);
export const CanonicalButtonIcon = styled(CanonicalStyled)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
`;

export const ConfidentialButtonIcon = styled(ConfidentialStyled)`
  fill: ${(props) => props.theme.colorPalette.branding.ink};
  background: ${(props) => props.theme.colorPalette.branding.white};
  margin: 0;
  border-radius: 30px;
  height: 16px;
  width: 16px;
`;

export const TableBodyStyled = styled.tbody<{ useTheme: PanelTheme }>`
  box-shadow: inset 0 -5px 6px 0 ${(props) => darken(0.05)(props.theme.colorPalette.panel[props.useTheme])};
`;

export const TableFooterStyled = styled.tfoot`
  min-height: 48px;
`;

export const ExpandButton = styled.button`
  position: relative;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  width: 34px;
  height: 20px;
  margin: 0 0 0 10px;
  flex-shrink: 0;
  span {
    background: ${(props) => props.theme.colorPalette.branding.white};
    z-index: 9999;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    padding: 2px 4px 2px 0px;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    font-size: ${(props) => props.theme.typography.paragraph.xsmall};
    text-align: center;
  }
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    background: ${(props) => props.theme.colorPalette.branding.ink};
    content: ' ';
    width: 100%;
    height: 100%;
  }
`;

export const ContractButton = styled(ExpandButton)<{
  useWhiteBackground?: boolean;
}>`
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  span {
    padding: 2px 0px 2px 4px;
    background: ${(props) => props.theme.colorPalette.branding.white};
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  }
`;

export const Tagline = styled.span`
  display: inline-block;
  font-style: italic;
  margin: 5px 0;
  flex-basis: 100%;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colorPalette.branding.ink};
  text-decoration: underline;
  margin: 0 0 4px;
  display: inline-block;
`;

export const ActionPlaceholder = styled.span`
  display: inline-block;
  width: 20px;
  display: flex;
`;

export const LoadingCell = styled.td`
  border-bottom: 1px solid
    ${(props) =>
      transparentize(0.3, props.theme.colorPalette.secondary.lightGrey)};
  padding: 28px 10px;
`;

export const StubCell = styled.span`
  width: 100%;
  height: 12px;
  display: block;
  background: ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;

const getBackground = (theme: any, color?: string) => {
  if (color) {
    return theme.tertiary[color];
  }
  return 'red';
};
export const MatchingIcon = styled.span<{ indicator: string }>`
  display: block;
  width: 10px;
  height: 9px;
  background: ${(props) =>
    getBackground(props.theme.colorPalette, props.indicator)};
  border-radius: 20px;
  position: absolute;
  right: 0;
  margin: 0 2px 2px 0;
  top: 2px;
`;

export const EpcBand = styled.div<{
  borderColour: string;
  backgroundColour: string;
}>`
  border-color: ${(props) => props.borderColour};
  border-width: 3px;
  border-style: solid;
  background-color: ${(props) => props.backgroundColour};
  padding: 10px;
  font-weight: bold;
`;
