import React, {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  FocusEvent,
} from 'react';
import SearchTextInput from 'components/Forms/Inputs/SearchInput/SearchTextInput';
import {
  BuildingIconStyled,
  CompanyIconStyled,
  customInputStyles,
  SearchTypeIndicator,
} from './QuickSearchStyled';
import { QuickSearchType } from './types';
import SearchWrapper from './SearchWrapper';
import { QUICK_SEARCH_OPTIONS } from './constants';

export interface SingleSearchFormProps {
  value?: string;
  searchType: QuickSearchType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const SingleSearchForm: React.FC<SingleSearchFormProps> = ({
  searchType,
  onSubmit,
  value,
  onChange,
  onFocus,
  onKeyUp,
}) => {
  const icon =
    searchType.name === QUICK_SEARCH_OPTIONS.buildings.name ? (
      <BuildingIconStyled aria-hidden="true" />
    ) : (
      <CompanyIconStyled aria-hidden="true" />
    );

  return (
    <SearchWrapper onSubmit={onSubmit}>
      <SearchTypeIndicator>{icon}</SearchTypeIndicator>
      <SearchTextInput
        name="quick-search"
        label={searchType.formLabel}
        onFocus={onFocus}
        labelIsHidden
        placeholder={searchType.formPlaceholder}
        customStyles={customInputStyles}
        onChange={onChange}
        onKeyUp={onKeyUp}
        value={value}
      />
    </SearchWrapper>
  );
};

export default SingleSearchForm;
