import { LoadingSpinner } from 'components/Spinner/Spinner';
import styled from 'styled-components/macro';

export const ThumbnailContainer = styled.div<{ fitted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 30px;
  min-width: 30px;
  height: ${(props) => (props.fitted ? '120px' : 'auto')};
  width: ${(props) => (props.fitted ? '200px' : 'auto')};
`;

export const ImageStyled = styled.img<{ isLoading: boolean; fitted: boolean }>`
  display: ${(props) => (props.isLoading ? 'none' : 'block')};
  position: relative;
  height: 100%;
  width: ${(props) => (props.fitted ? '100%' : 'auto')};
  max-height: ${(props) => (props.fitted ? 'auto' : '150px')};
  object-fit: ${(props) => (props.fitted ? 'cover' : 'contain')};
`;

export const LoadingSpinnerStyled = styled(LoadingSpinner)`
  position: absolute;
  height: 20px;
  width: 20px;
  fill: ${(props) => props.theme.colorPalette.branding.ink};
`;
