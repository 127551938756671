import React, { useEffect, ChangeEvent, FormEvent, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  quickSearchQuery,
  quickSearchReset,
  quickSearchSetSearchType,
} from 'store/actions/quickSearchActions';

import {
  getQuickSearchTerm,
  getQuickSearchTotalPages,
  getQuickSearchType,
} from 'store/selectors/quickSearchSelector';
import { getEntityIdSelector } from 'store/selectors/detailsSelectors';
import QuickSearchResults from './SearchResults/SearchResults';

import { QuickSearchTitle, QuickSearchWrapper } from './QuickSearchStyled';

import { QUICK_SEARCH_COPY, QUICK_SEARCH_OPTIONS } from './constants';

import {
  QuickSearchColorMode,
  QuickSearchOnResultItemClick,
  QuickSearchType,
} from './types';
import CloseButtonWithFade from './CloseButtonWithFade/CloseButtonWithFade';
import MultiSearchForm from './MultiSearchForm';
import SingleSearchForm from './SingleSearchForm';

export interface QuickSearchProps {
  onCloseButtonClick?: () => void;
  onInputFocus?: () => void;
  onResultItemClick?: QuickSearchOnResultItemClick;
  onSearchTermSet?: () => void;
  searchIsActive: boolean;
  showSummary?: boolean;
  isSingleSearchType?: boolean;
  defaultSearchType?: QuickSearchType;
  hideCloseButton?: boolean;
  mode?: QuickSearchColorMode;
  disableNonCanonicalResults?: boolean;
  formEmbed?: boolean;
  resetDetails?: boolean;
}

const QuickSearch: React.FC<QuickSearchProps> = ({
  onInputFocus,
  onCloseButtonClick,
  onResultItemClick,
  onSearchTermSet,
  searchIsActive,
  showSummary = true,
  defaultSearchType,
  isSingleSearchType,
  hideCloseButton,
  mode = 'light',
  disableNonCanonicalResults,
  formEmbed,
  resetDetails = true,
}: QuickSearchProps) => {
  const dispatch = useDispatch();

  const resourceId = useSelector(getEntityIdSelector);
  const searchTerm = useSelector(getQuickSearchTerm);
  const searchType = useSelector(getQuickSearchType);
  const currentPageNumber = useSelector(getQuickSearchTotalPages);
  const totalPages = useSelector(getQuickSearchTotalPages);

  const resetPanelOnMount = () => {
    if (searchTerm.length > 0) if (onSearchTermSet) onSearchTermSet();

    if (defaultSearchType && searchType.name !== defaultSearchType.name) {
      dispatch(quickSearchSetSearchType(defaultSearchType));
    }
  };

  useEffect(resetPanelOnMount, []);

  const closePanel = () => {
    dispatch(quickSearchReset(!resetDetails));

    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  const onSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputedTerm = event.target.value;

    const payload = {
      keyword: [inputedTerm, searchType.name],
      skipResetDetails: !resetDetails,
      pageNumber: 0,
    };

    dispatch(quickSearchQuery(payload));
  };

  const onSearchInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape' && searchIsActive) {
      dispatch(quickSearchReset(!resetDetails));
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) =>
    event.preventDefault();

  const handleBuildingSearchButtonClick = () =>
    dispatch(quickSearchSetSearchType(QUICK_SEARCH_OPTIONS.buildings));
  const handleCompanySearchButtonClick = () =>
    dispatch(quickSearchSetSearchType(QUICK_SEARCH_OPTIONS.companies));

  const handleShowMoreResultsClick = () => {
    const payload = {
      keyword: [searchTerm, searchType.name],
      skipResetDetails: !resetDetails,
      pageNumber: currentPageNumber + 1,
    };

    dispatch(quickSearchQuery(payload));
  };

  const canShowMoreResults =
    searchType === QUICK_SEARCH_OPTIONS.companies &&
    totalPages > currentPageNumber + 1;

  return (
    <QuickSearchWrapper>
      {!formEmbed && (
        <QuickSearchTitle searchIsActive={searchIsActive}>
          {QUICK_SEARCH_COPY.title}
        </QuickSearchTitle>
      )}
      {isSingleSearchType ? (
        <SingleSearchForm
          onFocus={onInputFocus}
          onChange={onSearchInputChange}
          onKeyUp={onSearchInputKeyUp}
          value={searchTerm}
          onSubmit={handleSubmit}
          searchType={searchType}
        />
      ) : (
        <MultiSearchForm
          onFocus={onInputFocus}
          onChange={onSearchInputChange}
          onKeyUp={onSearchInputKeyUp}
          value={searchTerm}
          onSubmit={handleSubmit}
          onClickBuildingSearchButton={handleBuildingSearchButtonClick}
          onClickCompanySearchButton={handleCompanySearchButtonClick}
          searchType={searchType}
        />
      )}
      {!hideCloseButton && searchIsActive && (
        <CloseButtonWithFade onClick={closePanel} />
      )}
      {(!resourceId || !resetDetails) && searchTerm.length > 1 && (
        <QuickSearchResults
          showSummary={showSummary}
          onResultItemClick={onResultItemClick}
          mode={mode}
          disableNonCanonicalResults={disableNonCanonicalResults}
          onShowMoreResultsClick={handleShowMoreResultsClick}
          canShowMoreResults={canShowMoreResults}
        />
      )}
    </QuickSearchWrapper>
  );
};

export default QuickSearch;
