import React, { FormEvent } from 'react';
import { SearchWrapperForm } from './QuickSearchStyled';

interface QuickSearchFormProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  formEmbed?: boolean;
}

const SearchWrapper: React.FC<QuickSearchFormProps> = ({
  onSubmit,
  formEmbed,
  children,
}) => {
  return formEmbed ? (
    <div>{children}</div>
  ) : (
    <SearchWrapperForm name="quick-search" onSubmit={onSubmit}>
      {children}
    </SearchWrapperForm>
  );
};

export default SearchWrapper;
