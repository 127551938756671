import styled from 'styled-components/macro';

import { BasicButton } from 'components/CallToActions/Button/Button';

interface QuickSearchToggleButtonStyledProps {
  left?: boolean;
  active?: boolean;
}

export const QuickSearchToggleButtonStyled = styled(BasicButton)<
  QuickSearchToggleButtonStyledProps
>`
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  border-radius: ${({ left }) => (left ? '4px 0 0 4px' : '0 4px 4px 0')};
`;

export const QuickSearchToggleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
