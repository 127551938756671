import React, {
  ChangeEvent,
  FocusEvent,
  RefObject,
  KeyboardEvent,
} from 'react';
import {
  SearchIconStyled,
  TextInputWrapper,
} from 'connected/QuickSearch/QuickSearchStyled';
import { StyledInput, StyledLabel } from './SearchInputStyled';

import { StyledInputProps } from './types';

interface SearchTextInputProps extends StyledInputProps {
  label: string;
  name: string;
  value?: string;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  labelIsHidden?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  isFullWidth?: boolean;
}

const SearchTextInput: React.FC<SearchTextInputProps> = ({
  label,
  name,
  placeholder,
  onChange,
  onFocus,
  onKeyUp,
  labelIsHidden,
  customStyles,
  inputRef,
  value,
  isFullWidth,
}: SearchTextInputProps) => (
  <TextInputWrapper>
    <StyledLabel htmlFor={name} labelIsHidden={labelIsHidden}>
      {label}
    </StyledLabel>
    <StyledInput
      id={name}
      name={name}
      type="text"
      onChange={onChange}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      autoComplete="off"
      customStyles={customStyles}
      ref={inputRef}
      value={value}
      isFullWidth={isFullWidth}
    />
    <SearchIconStyled />
  </TextInputWrapper>
);

export default SearchTextInput;
