import { createSelector } from 'reselect';

import { RootState } from 'store/rootReducer';

const getQuickSearch = (state: RootState) => state.quickSearch;

export const getQuickSearchTerm = createSelector(
  getQuickSearch,
  (quickSearch) => quickSearch.searchTerm,
);

export const getQuickSearchType = createSelector(
  getQuickSearch,
  (quickSearch) => quickSearch.searchType,
);

export const getQuickSearchCurrentPage = createSelector(
  getQuickSearch,
  (quickSearch) => quickSearch.meta.page,
);

export const getQuickSearchTotalPages = createSelector(
  getQuickSearch,
  (quickSearch) => quickSearch.meta.pages,
);
