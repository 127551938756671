export const compareVersions = (
  viewedVersion: string,
  currentVersion: string,
): number => {
  if (viewedVersion === currentVersion) return 0;

  const parts1 = viewedVersion.split('.').map(Number);
  const parts2 = currentVersion.split('.').map(Number);
  const maxLength = Math.max(parts1.length, parts2.length);
  for (let i = 0; i < maxLength; i += 1) {
    const num1 = parts1[i] ?? 0;
    const num2 = parts2[i] ?? 0;
    if (num1 !== num2) {
      return num1 > num2 ? 1 : -1;
    }
  }

  return 0;
};
