import { combineEpics } from 'redux-observable';
import loginEpic from 'store/epics/loginEpic';
import logoutEpic from 'store/epics/logoutEpic';
import loginRedirectEpic from 'store/epics/loginRedirectEpic';
import quickSearchEpic from 'store/epics/quickSearchEpic';
import { uploadToS3 } from 'store/epics/documentUploaderEpic';
import uploadSuccessCallback from 'store/epics/documentUploaderSuccessEpic';
import detailsEpic from 'store/epics/detailsEpic';
import updateDetailsEpic from 'store/epics/updateDetailsEpic';
import editRecordEpic from 'store/epics/editRecordEpic';
import documentDownloadEpic from 'store/epics/documentDownloadEpic';
import documentDeleteEpic from 'store/epics/documentDeleteEpic';
import newSaleEpic from 'store/epics/saveNewDealEpic';
import newLeaseEpic from 'store/epics/saveNewLeaseEpic';
import companySelectEpic from 'store/epics/companySelectEpic';
import companyDeleteEpic from 'store/epics/companyDeleteEpic';
import { submitTrackingEvent } from 'store/middleware/UserTracking';
import mapEpic from 'store/epics/mapEpic';
import boundariesEpic from 'store/epics/boundariesEpic';
import geometryEpic from 'store/epics/geometryEpic';
import searchEpic from 'store/epics/searchEpic';
import searchMapPinsEpic from 'store/epics/searchMapPinsEpic';
import matchingRecordsEpic from 'store/epics/matchingRecordsEpic';
import moveEntityEpic from 'store/epics/moveEntityEpic';
import searchResultsCountEpic from 'store/epics/searchCountEpic';
import noteDeleteEpic from 'store/epics/noteDeleteEpic';
import editMultipleRecordsEpic from 'store/epics/editMultipleRecordsEpic';
import portfolioSalesMatchingRecordsEpic from 'store/epics/portfolioSalesMatchingRecordsEpic';
import getPortfolioNamesEpic from 'store/epics/getPortfolioNamesEpic';
import portfolioNameSelectEpic from 'store/epics/portfolioNameSelectEpic';
import buildingDeleteEpic from 'store/epics/buildingDeleteEpic';
import pollingUpdateEpic from 'store/epics/pollingUpdateEpic';
import updateHistoryEpic from 'store/epics/updateHistoryEpic';
import pollingNewRecordEpic from 'store/epics/pollingNewRecordEpic';
import floorDeleteEpic from 'store/epics/floorDeleteEpic';
import userQuerySave from 'store/epics/userQuerySave';
import userQueryGet from 'store/epics/userQueryGet';
import userQueryUpdate from 'store/epics/userQueryUpdate';
import telemetryStatisticsGet from 'store/epics/telemetryStatisticsGet';
import usersListGet from 'store/epics/usersListGet';
import userFreezeEpic from 'store/epics/userFreezeEpic';
import userUnfreezeEpic from 'store/epics/userUnfreezeEpic';
import userProfileSave from 'store/epics/userProfileSave';
import userProfileGet from 'store/epics/userProfileGet';
import userProfileUpdate from 'store/epics/userProfileUpdate';
import renewEntityEpic from './epics/renewEntityEpic';
import splitEntityEpic from './epics/splitEntityEpic';
import hideEntityEpic from './epics/hideEntityEpic';
import deleteEntityEpic from './epics/deleteEntityEpic';
import addBreakRentEventEpic from './epics/addBreakRentEventEpic';
import scheduleDeleteEpic from './epics/scheduleDeleteEpic';
import userQueryDeleteEpic from './epics/userQueryDeleteEpic';
import deleteDebtEpic from './epics/deleteDebtEpic';
import watchRecordAddEpic from './epics/watchRecordAddEpic';
import watchRecordDeleteEpic from './epics/watchRecordDeleteEpic';
import compareRecordsEpic from './epics/compareRecordsEpic';
import mergeRecordsEpic from './epics/mergeRecordsEpic';
import valuationDeleteEpic from './epics/valuationDeleteEpic';
import buildingDeleteFromPortfolioEpic from './epics/buildingDeleteFromPortfolioEpic';
import searchStatisticsCreateEpic from './epics/searchStatisticsCreateEpic';
import searchStatisticsEpic from './epics/searchStatisticsEpic';
import relatedPartyDeleteEpic from './epics/relatedPartyDeleteEpic';
import buildingProtectedEpic from './epics/buildingProtectedEpic';
import athenaUserEpic from './epics/athenaUserEpic';
import athenaUserPostEpic from './epics/athenaUserPostEpic';
import companyParentsEpic from './epics/companyParentsEpic';
import companyGetDeletionDataEpic from './epics/companyGetDeletionDataEpic';
import scheduleTeamQueryEpic from './epics/scheduleTeamQueryEpic';
import epcSelectEpic from './epics/epcSelectEpic';
import addEpcEpic from './epics/addEpcEpic';
import fullScreenImageEpic from './epics/fullScreenImageEpic';
import primaryImageEpic from './epics/primaryImageEpic';
import pollingEpcEpic from './epics/pollingEpcEpic';
import unassignEpcEpic from './epics/unassignEpcEpic';

const rootEpic = combineEpics(
  loginEpic,
  logoutEpic,
  loginRedirectEpic,
  quickSearchEpic,
  uploadToS3,
  uploadSuccessCallback,
  detailsEpic,
  updateDetailsEpic,
  editRecordEpic,
  editMultipleRecordsEpic,
  documentDownloadEpic,
  documentDeleteEpic,
  newSaleEpic,
  newLeaseEpic,
  companySelectEpic,
  companyDeleteEpic,
  submitTrackingEvent,
  mapEpic,
  boundariesEpic,
  geometryEpic,
  searchEpic,
  searchMapPinsEpic,
  matchingRecordsEpic,
  moveEntityEpic,
  searchResultsCountEpic,
  noteDeleteEpic,
  portfolioSalesMatchingRecordsEpic,
  getPortfolioNamesEpic,
  portfolioNameSelectEpic,
  buildingDeleteFromPortfolioEpic,
  buildingDeleteEpic,
  pollingUpdateEpic,
  pollingNewRecordEpic,
  updateHistoryEpic,
  floorDeleteEpic,
  userQuerySave,
  userQueryGet,
  userQueryUpdate,
  telemetryStatisticsGet,
  usersListGet,
  userFreezeEpic,
  userUnfreezeEpic,
  userProfileSave,
  userProfileGet,
  userProfileUpdate,
  renewEntityEpic,
  splitEntityEpic,
  deleteEntityEpic,
  hideEntityEpic,
  addBreakRentEventEpic,
  scheduleDeleteEpic,
  userQueryDeleteEpic,
  deleteDebtEpic,
  watchRecordAddEpic,
  watchRecordDeleteEpic,
  compareRecordsEpic,
  mergeRecordsEpic,
  valuationDeleteEpic,
  searchStatisticsCreateEpic,
  searchStatisticsEpic,
  relatedPartyDeleteEpic,
  buildingProtectedEpic,
  athenaUserEpic,
  athenaUserPostEpic,
  companyParentsEpic,
  companyGetDeletionDataEpic,
  scheduleTeamQueryEpic,
  epcSelectEpic,
  addEpcEpic,
  fullScreenImageEpic,
  primaryImageEpic,
  pollingEpcEpic,
  unassignEpcEpic,
);

export default rootEpic;
