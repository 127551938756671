import React from 'react';
import { StyledLink } from 'components/Table/TableStyled';
import { TableCellType } from '../../types';
import { HeadingCell } from './HeadingCell';
import { HeadingThumbnailTooltipLinkCell } from './HeadingThumbnailTooltipLinkCell';

interface DefaultCellProps {
  values: TableCellType[];
}

export const ValuesCell: React.FC<DefaultCellProps> = ({ values }) => {
  const getHeadingCell = (value?: TableCellType, index = 0) => {
    if (!value) return null;

    const key = `heading_cell_${value.value}_${index}`;

    if (value.linkTo && !!value.thumbnailTooltipUrl) {
      return (
        <HeadingThumbnailTooltipLinkCell
          key={key}
          value={value}
          imageTooltipUrl={value.thumbnailTooltipUrl || ''}
        />
      );
    }

    if (value.linkTo) {
      return (
        <StyledLink to={value.linkTo} key={key}>
          <HeadingCell {...value} />
        </StyledLink>
      );
    }

    return <HeadingCell {...value} key={key} />;
  };

  return <>{values.map((v, i) => getHeadingCell(v, i))}</>;
};

export default ValuesCell;
